import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { AuthContext } from './authContext';
import './App.css';
import ChatForm from './components/ChatForm';
import ChatMessage from './components/ChatMessage'; // Add this line
import ChatHeader from './components/ChatHeader';
import { ApiService } from './services/ApiService';
import { v4 as uuidv4 } from 'uuid';
import { processSQLQuery, sanitizeString, formatSQLQuery, processSQLResult } from './ApiUtil';
import LoadingMessage from './components/LoadingMessage';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

const check_complete = async (accessToken: string | undefined, startTime: number, prompt: string) => {
  try {
    // Use the checkComplete function from ApiService to get the result
    const result_1 = await ApiService.checkComplete(accessToken, prompt);



    // If result_1 is null, it means "FETCH THE DATA" was detected, so no need to process further
    if (result_1) {
      const formattedText = result_1.split('\n').map((line: any, index: any) => (
        <p key={index}>{line}</p>
      ));
      const endComplete = performance.now();
      const loadingTimeToComplete = Math.round((endComplete - startTime) / 1000);


      return (
        <div>
          {formattedText}
          <p>({loadingTimeToComplete}s)</p>
        </div>
      );
    }

    return null; // return null if there's no valid result to display
  } catch (error) {
    console.error("Error in checkComplete:", error);
    return null;
  }
}

// Helper function to handle form submission and API call
const handleSubmit = async (
  prompt: string,
  fileName: any,
  accessToken: string | undefined,
  addMessage: (msg: any) => void,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setColumns: React.Dispatch<React.SetStateAction<any>>,
  setData: React.Dispatch<React.SetStateAction<any>>,
  isMultiTurnEnabled: boolean,
  isCompleteEnabled: boolean,
  setIsCompleteEnabled: React.Dispatch<React.SetStateAction<any>>,
  isOnySQLEnabled: boolean,
  setSqlQuery: React.Dispatch<React.SetStateAction<any>>,
  filters:string
) => {
  const prompt_id = uuidv4();
  if (!accessToken) {
    console.error("Access token is missing");
    return;
  }
  setIsLoading(true);
  const startTime = performance.now();
  
  addMessage({ type: 'user', text: prompt, prompt_id: prompt_id });
  prompt = sanitizeString(prompt) + " "+ filters;
  try {
    if (isCompleteEnabled) {
      const formattedText_with_time = await check_complete(accessToken, startTime, prompt);
      if (formattedText_with_time) {

        addMessage({ type: 'complete', text: formattedText_with_time });
        return
      }
    }
    let result: any
    
    if (isOnySQLEnabled) {
      result = await ApiService.getSQL(prompt, fileName, accessToken, isMultiTurnEnabled, isCompleteEnabled,prompt_id);
    } else {
      result = await ApiService.submitPrompt(accessToken, prompt, fileName, isMultiTurnEnabled, isCompleteEnabled,prompt_id)
    }


    const endTime = performance.now();
    const loadingTime = Math.round((endTime - startTime) / 1000);


    const { message, sql_result } = result; // Assuming SQL query is returned
    console.log("result", result)
    

    const bot_message = message.content
      .map((item: any) => {
        return item.text || ''; // Handle regular text messages
      })
      .join(' ')
      .replace(/__/g, '');

    // Handle the completion message separately
    const completion_message = message.content
      .map((item: any) => {
        if (item.complete) {
          return item.complete;
        }
        return ''; // Skip if no completion data
      })
      .filter(Boolean) // Remove empty strings
      .join(' ');

    const bot_message_with_time = `${bot_message} (${loadingTime}s)`;
    const completion_message_with_time = `${completion_message} (${loadingTime}s)`;



    // Add bot message first
    if (bot_message) {
      addMessage({ type: 'bot', text: bot_message_with_time });
    }


    // If there's a completion message, add it separately as a new message type
    if (completion_message) {

      addMessage({ type: 'complete', text: completion_message_with_time });
      return
    }

    // Suggestions
    if (message && message.content.some((item: any) => item.type === 'suggestions' && item.suggestions?.length > 0)) {


      // Filter out only those items that are of type 'suggestions'
      const suggestions = message.content
        .filter((item: any) => item.type === 'suggestions') // Ensure the type is suggestions
        .flatMap((item: any) => item.suggestions); // Flatten the array

      // Add message
      addMessage({ type: 'suggestions', suggestions: suggestions })
      return
    };

    const sql_query = formatSQLQuery(message);
    if(sql_query!=null){
      
      setSqlQuery(sql_query)
      if (isOnySQLEnabled) {
        processSQLQuery(sql_query, addMessage, prompt_id);
      } else {
        processSQLResult(sql_result, sql_query, setColumns, addMessage);
      }
    }

  } catch (error) {


    alert("An error occurred. Please Refresh the page try again. If the issue persists, contact dune@cimpress.com")

  } finally {
    setIsLoading(false); // Ensure loading state is reset
  }
};


const executeSQLStatement = async (accessToken: string | undefined, sql_query: string, setColumns: React.Dispatch<React.SetStateAction<any>>,
  addMessage: (msg: any) => void,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>, promptId: string) => {
  setIsLoading(true);
  const { sql_result } = await ApiService.executeSQL(accessToken, promptId);
  processSQLResult(sql_result, sql_query, setColumns, addMessage);
  setIsLoading(false);

}


// Main application component
const App: React.FC = () => {
  const [prompt, setPrompt] = useState('');
  const [fileName, setFileName] = useState<string>("");
  const [fileOptions, setFileOptions] = useState<string[]>([]);
  const { accessToken } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [columns, setColumns] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [hasStartedChat, setHasStartedChat] = useState<boolean>(false);
  const [chatHistory, setChatHistory] = useState<any[]>([]);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [loadingTime, setLoadingTime] = useState<number>(0);
  const [isMultiTurnEnabled, setIsMultiTurnEnabled] = useState<boolean>(true);
  const [isCompleteEnabled, setIsCompleteEnabled] = useState<boolean>(false);
  const [isOnySQLEnabled, setIsOnySQLEnabled] = useState<boolean>(false);
  const [sqlQuery, setSqlQuery] = useState('');
  const lastMessageRef = useRef<HTMLDivElement | null>(null);
  const [filters, setFilter] = useState('');

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();


  // Read the promptId from URL
  const [promptId, setPromptId] = useState(searchParams.get('promptId'));

  useEffect(() => {
    // Once we read the promptId, remove it from the URL
    if (promptId) {
      navigate(window.location.pathname, { replace: true });
    }
  }, [promptId, navigate]);


  const addMessage = (message: any) => {
    setChatHistory((prevHistory) => [
      ...prevHistory,
      { ...message, id: uuidv4(), ref: lastMessageRef, sqlQuery: sqlQuery }, // Add a unique ID to each message
    ]);
  };


  useEffect(()=>{
    console.log(filters,"Filter in main")
  },[filters])


  useEffect(() => {
    const fetchFileNames = async () => {
      if (!accessToken) return; // Exit early if there's no access token

      try {
        const files = await ApiService.fetchFileNames(accessToken);
        const options = files
        setFileOptions(options); // Set the mapped options to the state
      } catch (error) {
        console.error('Error fetching file names:', error);
      }
    };

    const fetchData = async () => {
      if (promptId && accessToken) {
        try {
          // Call the getByPromptId function with the promptId
          const result = await ApiService.getByPromptId(accessToken, promptId);
          // if no data then return 
          if(result.length==0){
            await fetchFileNames();
            return
          }
          await fetchFileNames();
          console.log("result by prompt id", result[0]?.PROMPT); // Use optional chaining

          if (result && result.length === 1) {
            const { PROMPT, SEMANTIC_FILE, SQL_QUERY } = result[0];

            setSqlQuery(SQL_QUERY);
            // Add a message with the prompt as a message by the user
            addMessage({ type: 'user', text: PROMPT });

            // Fetch file names
            setFileName(SEMANTIC_FILE.replace(".yaml",""));

            // Process the SQL query and add message
            processSQLQuery(SQL_QUERY, addMessage, promptId);
          }
        } catch (error) {
          console.error('Error fetching data by promptId:', error);
          await fetchFileNames(); // Fetch file names in case of an error
        }
      } else {
        await fetchFileNames(); // Fetch file options if no promptId is provided
      }
    };

    fetchData();
  }, [promptId, accessToken]);


  const fetchChatHistory = async () => {
    if (!accessToken || !fileName) return; // Ensure accessToken and fileName are available
    try {
      const history = await ApiService.fetchChatHistory(accessToken, fileName); // Fetch chat history based on accessToken
      // Update state with unique IDs and add 'pre_chat' type messages
      history.forEach((item: any) => {
        const message = {
          type: 'pre_chat',
          text: item[0], // Get the first element as value
          id: uuidv4(), // Generate a unique ID
        };
        addMessage(message); // Add the message to chat history
      });
    } catch (error) {
      console.error('Error fetching chat history:', error);
    }
  };



  useEffect(() => {
    if (!promptId) {
      setChatHistory(prevHistory => prevHistory.filter(message => message.type !== 'pre_chat'));
      fetchChatHistory(); // Fetch chat history when fileName or accessToken changes
    }
  }, [fileName, accessToken]);

  // Handle form submission
  const handleSubmitWrapper = useCallback((event: React.FormEvent<HTMLFormElement> | null = null, promptData: string = '') => {
    if (event) {
      event.preventDefault();
    }
    setHasStartedChat(true);
    console.log(prompt || promptData);
    handleSubmit(promptData || prompt, fileName, accessToken, addMessage, setIsLoading, setColumns, setData
      , isMultiTurnEnabled, // Pass the state values to handleSubmit
      isCompleteEnabled, setIsCompleteEnabled, isOnySQLEnabled, setSqlQuery,filters);
    setPrompt('')
  },
    [prompt, fileName, accessToken, filters, isMultiTurnEnabled, isCompleteEnabled, isOnySQLEnabled]);

  const executeSQLStatementWrapper = useCallback((event: React.FormEvent<HTMLFormElement> | null = null, promptIddata = '', promptData: string = '') => {
    if (event) {
      event.preventDefault();
    }
    executeSQLStatement(accessToken, promptData || sqlQuery, setColumns, addMessage, setIsLoading, promptIddata || promptId || '')
  },
  [accessToken, sqlQuery, promptId]);


  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [chatHistory]);


  return (
    <>

      <div className='out-container' style={{ display: 'flex', height: '100vh' }}>
        <ChatHeader
          isMultiTurnEnabled={isMultiTurnEnabled}
          setIsMultiTurnEnabled={setIsMultiTurnEnabled}
          isCompleteEnabled={isCompleteEnabled}
          setIsCompleteEnabled={setIsCompleteEnabled}
          isOnySQLEnabled={isOnySQLEnabled}
          setIsOnySQLEnabled={setIsOnySQLEnabled}
          columns={columns}
          setFilter={setFilter}

        />


        <div
          className="chat-app"
        >

          <div className="chat-container" ref={chatContainerRef}>


            {/* Always render chat history */}
            {chatHistory.map((msg, index) => (
              <div
                key={msg.id}
                ref={index === chatHistory.length - 1 ? lastMessageRef : null} // Attach the ref to the last message
              >
                <ChatMessage message={msg} executeSQLStatement={executeSQLStatementWrapper} setPromptId={setPromptId} setSqlQuery={setSqlQuery} handleSubmitWrapper={handleSubmitWrapper} />

              </div>
            ))}

            {isLoading && <LoadingMessage isLoading={isLoading} />}
          </div>
          <ChatForm
            handleSubmitWrapper={handleSubmitWrapper}
            prompt={prompt}
            fileName={fileName}
            setFileName={setFileName}
            fileOptions={fileOptions}
            isLoading={isLoading}
            setHasStartedChat={setHasStartedChat}
          />
        </div>
      </div>
    </>
  );
};

export default App; 
