import React from 'react';
import Switch from 'react-switch';
import { PlatformHeader } from './header';
import './ChatHeader.css';
import FilterPromptGenerator from './FilterComponent';

const ChatHeader: React.FC<{
  isMultiTurnEnabled: boolean;
  setIsMultiTurnEnabled: (value: boolean) => void;
  isCompleteEnabled: boolean;
  setIsCompleteEnabled: (value: boolean) => void;
  isOnySQLEnabled: boolean;
  setIsOnySQLEnabled: (value: boolean) => void;
  columns:any;
  setFilter:any
}> = ({
  isMultiTurnEnabled,
  setIsMultiTurnEnabled,
  isCompleteEnabled,
  setIsCompleteEnabled,
  isOnySQLEnabled,
  setIsOnySQLEnabled,columns,setFilter
}) => {
  return (
    <>
      <PlatformHeader />
      <div className="chat-header">
        <div className="switches-container">
          <label className="switch-label" data-tooltip="Enable bot to use chat history">
            <span>Multi-turn</span>
            <Switch
              onChange={() => setIsMultiTurnEnabled(!isMultiTurnEnabled)}
              checked={isMultiTurnEnabled}
              onColor="#90ee90"
              onHandleColor="#2693e6"
              handleDiameter={15}
              uncheckedIcon={false}
              checkedIcon={false}
              height={20}
              width={48}
            />
          </label>
          <label className="switch-label" data-tooltip="Allow LLM directly answers on data.">
            <span>Smart Insights</span>
            <Switch
              onChange={() => setIsCompleteEnabled(!isCompleteEnabled)}
              checked={isCompleteEnabled}
              onColor="#90ee90"
              onHandleColor="#2693e6"
              handleDiameter={15}
              uncheckedIcon={false}
              checkedIcon={false}
              height={20}
              width={48}
            />
          </label>
          <label className="switch-label" data-tooltip="Generates only SQL">
            <span>Generate SQL</span>
            <Switch
              onChange={() => setIsOnySQLEnabled(!isOnySQLEnabled)}
              checked={isOnySQLEnabled}
              onColor="#90ee90"
              onHandleColor="#2693e6"
              handleDiameter={15}
              uncheckedIcon={false}
              checkedIcon={false}
              height={20}
              width={48}
            />
          </label>
          {/* <FilterPromptGenerator columnOptions={columns} setFilter={setFilter} />  */}
        </div>
      </div>
    </>
  );
};

export default ChatHeader;
